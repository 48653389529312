@import '../../styles/includes';

.ProductAccessoryCard {
    $root: &;

    height: 100%;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    background-color: $colorWhite;
    display: block;
    color: $colorBlack;
    text-decoration: none;
    transition: box-shadow 0.25s ease;

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 8px 24px -1px rgba(0, 0, 0, 0.5);
        }
    }

    &__Container {
        padding: $spacingM;
        padding-bottom: $spacingL;

        @include media(m) {
            padding: $spacingL;
        }
    }

    &__ImageWrap {
        position: relative;
        width: 100%;
        height: 230px;
        background-color: $colorWhite;
        margin-bottom: 28px;
    }

    &__Image {
        object-fit: cover;
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.25s ease;

        &--Loaded {
            opacity: 1;
        }
    }

    &__Title {
        @extend %h4;
        font-family: $fontLight;
        margin-bottom: $spacingM;
        transition: color 0.25s ease;
        will-change: color;

        @include media(m) {
            margin-bottom: $spacingM;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }
        }
    }

    &__Description {
        @extend %p;
        margin-bottom: $spacingL;
        font-size: 14px;

        @include media(m) {
            font-size: 16px;
        }
    }

    &__Button {
        display: flex;
        align-items: center;

        span {
            margin-left: $spacingS;
            transition: margin-left 0.25s ease;
            will-change: margin-left;
        }

        path {
            transition: fill 0.25s ease;
            will-change: fill;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                span {
                    margin-left: $spacingM;
                }

                path {
                    fill: $colorRed50;
                }
            }
        }
    }

    &__ButtonText {
        font-family: $fontMedium;
        font-size: 18px;
        transition: color 0.25s ease;
        will-change: color;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }
        }
    }
}
