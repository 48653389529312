@import '../../styles/includes';

.ProductCardList {
    &__Grid {
        @extend %container;
        display: grid;
        grid-template-columns: 1fr;

        &--Legacy {
            grid-template-columns: 1fr;
            row-gap: 64px;
            column-gap: 0;

            @include media(m) {
                grid-template-columns: 1fr 1fr;
                row-gap: 86px;
                column-gap: 24px;
            }
        }

        &--Accessory {
            grid-template-columns: 1fr;
            gap: 16px;

            @include media(m) {
                grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
                gap: 24px;
            }
        }
    }

    &__CategoryContainer {
        @extend %container;
        margin-top: 48px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__CategoryTitle {
        @extend %h3;
        font-family: $fontLight;
        margin-bottom: 24px;
    }

    &__CategoryGrid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        padding-bottom: 48px;
        border-bottom: 1px solid $colorRed50;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }

        @include media(ml) {
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;
        }
    }
}