@import '../../styles/includes';

.ProductCurrentCard {
    $root: &;

    position: relative;
    display: block;
    min-height: 343px;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    background-color: $colorWhite;
    will-change: box-shadow;
    transition: box-shadow 0.25s ease;
    border-bottom: 1px solid $colorRed50;
    color: $colorBlack;

    &__Container {
        padding: 32px 0 42px 0;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;

        @include media(m) {
            flex-direction: row;
            padding: 60px 0;
        }
    }

    &__DescriptionWrapper {
        width: 100%;
        margin-right: 70px;
    }

    &__LogoImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 28px;
        margin-bottom: 28px;

        @include media(m) {
            height: 40px;
            margin-bottom: 20px;
        }

        img {
            object-position: left;
        }
    }

    &__Description {
        font-family: $fontLight;
        font-size: 18px;
        line-height: normal;
        margin-bottom: 24px;

        @include media(m) {
            font-size: 20px;
            line-height: 1.35;
            margin-bottom: 16px;
        }
    }

    &__Button {
        display: flex;
        align-items: center;

        span {
            margin-left: $spacingS;
            transition: margin-left 0.25s ease;
            will-change: margin-left;
        }

        path {
            transition: fill 0.25s ease;
            will-change: fill;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                span {
                    margin-left: $spacingM;
                }

                path {
                    fill: $colorRed50;
                }
            }
        }
    }

    &__ButtonText {
        font-family: $fontMedium;
        font-size: 18px;
        transition: color 0.25s ease;
        will-change: color;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }
        }
    }

    &__Image {
        width: 100%;
    }

    &__ProductImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 110px;
        margin-bottom: 16px;
        padding-bottom: calc((300 / 666) * 100%);

        @include media(m) {
            height: 210px;
            margin-bottom: 8px;
            padding-bottom: 0;
        }

        img {
            will-change: filter;
            transition: filter 0.5s ease;
            z-index: -1;
            object-position: left;

            @include media(m) {
                object-position: center right;
            }
            @media (hover: hover) {
                #{$root}:hover & {
                    filter: brightness(1.16);
                }
            }
        }
    }
}
