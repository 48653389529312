@import '../../styles/includes';

.ProductHighlightItem {
    $root: &;
    position: relative;
    display: block;
    min-height: 343px;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    background-color: $colorWhite;
    will-change: box-shadow;
    transition: box-shadow 0.25s ease;

    color: $colorBlack;

    &__Container {
        padding-bottom: 42px;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        border-bottom: 1px solid $colorRed50;
        @include media(m) {
            flex-direction: column-reverse;
            justify-content: space-between;
        }
    }

    &__DescriptionWrapper {
        width: 100%;
        margin-right: 70px;

        @include media(m) {
            margin-right: 0;
            gap: 40px;
            display: flex;
            max-width: none;
        }
    }

    &__LogoImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 28px;
        margin-bottom: 28px;

        @include media(m) {
            height: 75px;
            margin: auto;
            width: 50%;
        }

        img {
            object-position: left;
        }
    }

    &__DescriptionTextWrapper {
        @include media(m) {
            width: 50%;
        }
    }

    &__Description {
        font-family: $fontLight;
        font-size: 18px;
        line-height: normal;
        margin-bottom: 24px;

        @include media(m) {
            font-size: 20px;
            line-height: 1.35;
            width: 100%;
            margin-right: auto;
            margin-bottom: 16px;
        }
    }

    &__Image {
        width: 100%;
    }

    &__ProductImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: 16px;

        @include media(m) {
            margin-bottom: 46px;
        }

        img {
            will-change: filter;
            transition: filter 0.5s ease;
            object-position: left;
            position: static !important;

            @include media(m) {
                object-position: center;
            }

            @media (hover: hover) {
                #{$root}:hover & {
                    filter: brightness(1.16);
                }
            }
        }
    }

    &__Button {
        display: flex;
        align-items: center;

        span {
            margin-left: $spacingS;
            transition: margin-left 0.25s ease;
            will-change: margin-left;
        }

        path {
            transition: fill 0.25s ease;
            will-change: fill;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                span {
                    margin-left: $spacingM;
                }

                path {
                    fill: $colorRed50;
                }
            }
        }
    }

    &__ButtonText {
        font-family: $fontMedium;
        font-size: 18px;
        transition: color 0.25s ease;
        will-change: color;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }
        }
    }

    &__ProductImage {
        position: relative;
        opacity: 0;
        will-change: opacity, filter;
        transition: opacity 0.5s ease, filter 0.5s ease;
        transform: scale(1);
        filter: blur(20px);
        z-index: -1;
        object-position: left;

        @include media(m) {
            object-position: center;
        }

        &--Loaded {
            opacity: 1;
            filter: blur(0);
            filter: brightness(1);
        }

        @media (hover: hover) {
            #{$root}:hover & {
                filter: brightness(1.16);
            }
        }
    }
}
