@import '../../styles/includes';

.ProductLegacyCard {
    $root: &;

    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    background-color: $colorWhite;
    will-change: box-shadow;
    transition: box-shadow 0.25s ease;
    color: $colorBlack;

    &__Container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__LogoImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 40px;
        margin-bottom: 8px;

        img {
            object-position: left;
        }
    }

    &__ProductImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 180px;
        margin-bottom: 16px;

        img {
            object-position: left;
            will-change: filter;
            transition: filter 0.5s ease;
            object-fit: contain !important;

            @include media(m) {
                object-position: left;
            }

            @media (hover: hover) {
                #{$root}:hover & {
                    filter: brightness(1.16);
                }
            }
        }
    }

    &__Description {
        font-family: $fontLight;
        font-size: 20px;
        line-height: 1.35;

        margin-bottom: $spacingM;
    }

    &__Button {
        display: flex;
        align-items: center;

        span {
            margin-left: $spacingS;
            transition: margin-left 0.25s ease;
            will-change: margin-left;
        }

        path {
            transition: fill 0.25s ease;
            will-change: fill;
        }

        @media (hover: hover) {
            #{$root}:hover & {
                span {
                    margin-left: $spacingM;
                }

                path {
                    fill: $colorRed50;
                }
            }
        }
    }

    &__ButtonText {
        font-family: $fontMedium;
        font-size: 18px;
        transition: color 0.25s ease;
        will-change: color;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }
        }
    }
}